import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';

function Home() {
  useEffect(() => {
    document.title = `home - ${window.location.hostname}`;
  }, []);
  return (
    <Container fluid="md" className="pt-2 mt-5">
      <h1>Homepage or whatever.</h1>
      <Footer />
  </Container>
  );
}

export default Home;
