import React from 'react';
import { Link } from 'react-router-dom';

function FooterComponent() {
  return (
    <footer className="container-fluid d-flex flex-column flex-lg-row justify-content-lg-between align-items-center py-2 border-top mt-5">
      <p className="mb-2 mb-lg-0 text-body-secondary text-center text-lg-start">PPV.LAND {new Date().getFullYear()}</p>
      <ul className="nav justify-content-center justify-content-lg-end mb-0">
        <li className="nav-item">
          <a href={process.env.REACT_APP_DISCORD_LINK} className="nav-link px-2 text-body-secondary">
            Discord
          </a>
        </li>
        <li className="nav-item">
          <Link as={Link} to="/changelog" className="nav-link px-2 text-body-secondary">
            Changelog
          </Link>
        </li>
        <li className="nav-item">
          <Link as={Link} to="/vip" className="nav-link px-2 text-body-secondary">
            Donate / VIP Access
          </Link>
        </li>
        <li className="nav-item">
          <Link as={Link} to="/contact" className="nav-link px-2 text-body-secondary">
            Contact us
          </Link>
        </li>
      </ul>
    </footer>
  );
}

export default FooterComponent;