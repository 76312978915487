import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';

function NotFound() {
  useEffect(() => {
    document.title = `page not found - ${window.location.hostname}`;
  }, []);
  return (
    <Container fluid="md" className="pt-2 mt-5">
      <h1 className="text-center">This page was not found.</h1>
      <h5 className="text-center my-5">Options: <Link as={Link} to="/">homepage</Link></h5>
      <Footer />
    </Container>
  );
}

export default NotFound;
