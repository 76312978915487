import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// pages
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Register from './pages/Register';
import Recover from './pages/Recover';
import Navbar from './components/Navbar';
//require('dotenv').config();
import './styles.css';

function App() {
  return (
    <Router>
      <div>
        {/* Navbar will always be visible */}
        <Navbar />

        {/* Routes will switch between different pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/recover" element={<Recover />} />
          {/* Catch-all route for undefined URLs */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
