import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

function AuthCardNav() {
  const location = useLocation();
  return (
    <Nav variant="tabs">
      <Nav.Item>
        <Nav.Link as={Link} to="/auth/login" active={location.pathname === '/auth/login'}>
          Login
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/auth/register" active={location.pathname === '/auth/register'}>
          Register
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/auth/recover" active={location.pathname === '/auth/recover'}>
          Recover account
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default AuthCardNav;