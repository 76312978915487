import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import AuthCardNav from '../components/AuthCardNav';
import OAuthLogin from '../components/OAuthLogin';
import Footer from '../components/Footer';

function Register() {
  useEffect(() => {
    document.title = `register - ${window.location.hostname}`;
  }, []);
  return (
    <Container fluid="md" className="pt-2 mt-5">
      <div>
        <h1>Create a PPV.LAND account</h1>
        <Row className="gy-5 my-5 mt-1">
          <Col md={8}>
            <Card>
              <Card.Header>
                <AuthCardNav />
              </Card.Header>
              <Card.Body>
                <Card.Title>Special title treatment</Card.Title>
                <Card.Text>
                  With supporting text below as a natural lead-in to additional content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <OAuthLogin />
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
}

export default Register;
