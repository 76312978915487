import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

function NavbarComponent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('username');
    localStorage.removeItem('session');
    setIsLoggedIn(false);
    setUsername(null);
  };

  useEffect(() => {
    const authStatus = localStorage.getItem('authenticated') === 'true';
    const storedUsername = localStorage.getItem('username');

    setIsLoggedIn(authStatus);
    setUsername(storedUsername);
  }, []);
  const location = useLocation();
  return (
    <Navbar expand="lg">
      <div className="container-fluid">
        <Navbar.Brand as={Link} to="/">
          <img
            src="/assets/img/ppv-halloween.png"
            height="30px"
            className="d-inline-block"
            alt="PPV.LAND"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/request" active={location.pathname === '/request'}>Request</Nav.Link>
            <Nav.Link as={Link} to="/vods" active={location.pathname === '/vods'}>VODs</Nav.Link>
            <Nav.Link href={process.env.REACT_APP_MIRROR_LIST}>Mirror list</Nav.Link>
            <Nav.Link href={process.env.REACT_APP_DISCORD_LINK}>Discord</Nav.Link>
          </Nav>
          <Nav>
            {isLoggedIn ? (
              <NavDropdown title={username} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/settings">Settings & Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/settings/vip">VIP status & help</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Sign Out</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link as={Link} to="/auth/login" active={location.pathname === '/auth/login'}>Login</Nav.Link>
                <Nav.Link as={Link} to="/auth/register" active={location.pathname === '/auth/register'}>Register</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default NavbarComponent;