import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import AuthCardNav from '../components/AuthCardNav';
import OAuthLogin from '../components/OAuthLogin';
import Footer from '../components/Footer';

function Login() {
  useEffect(() => {
    document.title = `login - ${window.location.hostname}`;
  }, []);
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Unsure which to use?</Popover.Header>
      <Popover.Body>
        If you logged in using <strong>Discord</strong> or <strong>Twitch</strong>, use the email address for those accounts.
      </Popover.Body>
    </Popover>
  );
  return (
    <Container fluid="md" className="pt-2 mt-5">
      <div>
        <h1>Login to PPV.LAND</h1>
        <Row className="gy-5 my-5 mt-1">
          <Col md={8}>
            <Card>
              <Card.Header>
                <AuthCardNav />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Form>
                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Username or email address</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control type="text" placeholder="Username or email address" />
                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                          <Button variant="outline-secondary" id="button-addon2">
                            ?
                          </Button>
                        </OverlayTrigger>
                      </InputGroup>
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="••••••••••••" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="remember">
                      <Form.Check type="checkbox" label="Remember this session" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <OAuthLogin />
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
}

export default Login;
