import React, { createContext, useContext, useState, useCallback } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

// Create the context for Toast
const ToastContext = createContext();

// Global toast object (to use in non-React files)
let toast = {};

// Custom hook to use Toast in React components
export function useToast() {
  return useContext(ToastContext);
}

// ToastProvider component to wrap your app
export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  // Function to add a new toast
  const addToast = useCallback((title, description, variant = 'success') => {
    setToasts((prevToasts) => [...prevToasts, { title, description, variant }]);
  }, []);

  // Remove a toast after it's closed
  const removeToast = (index) => {
    setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
  };

  // The functions to be exposed as toast.success, toast.danger, etc.
  toast = {
    success: (title, options = {}) => addToast(title, options.description, 'success'),
    danger: (title, options = {}) => addToast(title, options.description, 'danger'),
    info: (title, options = {}) => addToast(title, options.description, 'info'),
    warning: (title, options = {}) => addToast(title, options.description, 'warning'),
  };

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <ToastContainer position="top-end" className="p-3">
        {toasts.map((toast, index) => (
          <Toast
            key={index}
            onClose={() => removeToast(index)}
            delay={3000}
            autohide
            bg={toast.variant}
          >
            <Toast.Header>
              <strong className="me-auto">{toast.title}</strong>
            </Toast.Header>
            {toast.description && <Toast.Body>{toast.description}</Toast.Body>}
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
}

// Export the toast object for use in non-React files
export { toast };
